import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  link: {
    color: "#273746",
  },
  sectionDiv: {
    padding: "0px 0px 60px 0px",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.sectionDiv}>
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            Based on the vision and direction of your business, we will design
            and customize a website that reflects your law firm's personality
            and theme. This process is iterative and requires multiple
            consultations to get it right.
          </p>
          <p className={classes.description}>
            Once your web design has been agreed upon, we implement our Custom
            React platform to ensure a sleek and efficient website. This will
            start ranking you on Google and will set the stage for Search Engine
            Optimization (SEO). Without the right design, you could be wasting
            your time and money on inefficient SEO.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"

import DevicesIcon from "@material-ui/icons/Devices"
import CreateIcon from "@material-ui/icons/Create"
import CloudDoneIcon from "@material-ui/icons/CloudDone"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h2 className={classes.title}>Law Firm Website Design</h2>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={8}
          lg={11}
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            Atlas Agency is a marketing company that provides lawyers with
            professional web design services. Whether your practice area is
            family law, personal injury or corporate law, we have extensive
            experience in providing all types of law firms with professional
            websites.
          </p>
          <p className={classes.description}>
            We provide lawyers with websites that connect you to leads, engages
            potential clients, and converts those leads into clients. Bring your
            website and it's abilities into the here and now by modernizing,
            updating, and equipping it with the latest in web design. Our
            marketing strategy will ensure your website is up to date while also
            enhancing the user experience
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={11} sm={10} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="Our web design services provide a smooth transition between webpages and minimal loading time. This will enable your website to be easily accessible and responsive, whether on the mobile or on the web. "
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting & Security"
              description="All our web designs are HTTPS secured, so the integrity and confidentiality of data between the user's computer and the website are always secure."
              icon={CloudDoneIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Optimization"
              description="Potential clients will be using their phones and other devices to find a lawyer that they can depend on, so a website that is mobile friendly and able to be viewed on multiple devices is crucial. "
              icon={DevicesIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

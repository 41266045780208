import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "lawyer-web-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div style={{ padding: "60px 0px 60px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center">
          <Grid
            item
            md={5}
            sm={10}
            lg={5}
            xs={10}
            style={{ paddingBottom: "60px" }}
          >
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="lawyer-web-design"
            />
          </Grid>
          <Grid item lg={4} md={10} sm={11} xs={11}>
            <h3 className={classes.title}>Launch with Confidence</h3>
            <h6 className={classes.description}>
              The Importance of Web Design
            </h6>
            <p className={classes.description}>
              We are constantly educating ourselves on the latest in website
              development and technologies. Atlas Agency in Toronto wants to
              stay ahead of the game by providing all our lawyer and law firm
              clients with professional and reliable website designs.
            </p>
            <p className={classes.description}>
              If you're considering building a custom website for your law firm,
              consider this, if a website is not appealing to your online
              consumers, 88% of them will turn away and never visit it again. As
              a professional law firm, you need something more than your basic
              WordPress design in order to attract leads and also keep them on
              your website.
            </p>
            <p className={classes.description}>
              It's part of your job to legally protect your clients, and it's
              our job to protect your website and those who view it. Website
              visitors expect a secure and private online experience, and web
              page design for lawyers is no different. All our web designs are
              HTTPS secured, so the integrity and confidentiality of data
              between the user's computer and the website are always secure.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
